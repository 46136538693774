import clsx from 'clsx';

import { SpinnerProps } from './Spinner.types';

const Spinner = ({
  width = 18,
  height = 18,
  colorClassName = 'stroke-gray-0 dark:stroke-blue-600',
  className,
  testId = 'loader-spinner',
}: SpinnerProps) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 66 66"
    data-testid={testId}
  >
    <circle
      cx="33"
      cy="33"
      fill="none"
      r="28"
      stroke="currentColor"
      strokeDasharray="40, 134"
      strokeDashoffset="325"
      strokeLinecap="round"
      strokeWidth={8}
      className={clsx(colorClassName, 'animate-spinner')}
    />
  </svg>
);

export default Spinner;
