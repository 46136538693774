import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import SearchBar from '@bluecodecom/common/ui/SearchBar';

import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';

const Search = () => {
  const { t } = useTranslation();
  const {
    search,
    isLoading,
    selectedBankName,
    toggleSearchingFocused,
    onSearchChange,
    onClearSearch,
  } = banksHooks.useBanksContext();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClear = useCallback(() => {
    onClearSearch();
    inputRef.current?.focus({ preventScroll: true });
  }, [onClearSearch]);

  return (
    <div className="relative z-50 w-full">
      <SearchBar
        ref={inputRef}
        label={
          selectedBankName
            ? t('BankSearchForm.children-label-search', {
                bank: selectedBankName,
              })
            : t('BankSearchForm.label-search')
        }
        isLoading={isLoading}
        value={search}
        onChange={onSearchChange}
        onFocus={toggleSearchingFocused}
        onBlur={toggleSearchingFocused}
        onClear={handleClear}
        className="px-4"
        testId="bank-search"
      />
    </div>
  );
};

export default Search;
