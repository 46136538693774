import clsx from 'clsx';

import Typo from '../../../Typo';
import { SubtitleProps } from './Subtitle.types';

const Subtitle = ({
  children,
  className,
  color,
  size = 'sm',
  weight = '300',
  isTruncated,
}: SubtitleProps) => (
  <Typo
    color={color}
    size={size}
    className={clsx(
      isTruncated && 'overflow-hidden text-ellipsis whitespace-nowrap',
      className,
    )}
    weight={weight}
  >
    {children}
  </Typo>
);

export default Subtitle;
