import { useContext } from 'react';

import TabsContext from './Tabs.context';

export default function useTabs() {
  const ctx = useContext(TabsContext);

  if (!ctx) {
    throw new Error('useTabs must be used within a TabsProvider');
  }

  return ctx;
}
