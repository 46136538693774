import FullScreen from './FullScreen';
import Indicator from './Indicator';
import { LoaderProps } from './Loader.types';
import Page from './Page';
import Pulse from './Pulse';
import Spinner from './Spinner';

export const Loader = (props: LoaderProps) => {
  switch (props.type) {
    case 'fullscreen': {
      const { type, ...p } = props;
      return <FullScreen {...p} />;
    }
    case 'indicator': {
      const { type, ...p } = props;
      return <Indicator {...p} />;
    }
    case 'spinner': {
      const { type, ...p } = props;
      return <Spinner {...p} />;
    }
    case 'pulse': {
      const { type, ...p } = props;
      return <Pulse {...p} />;
    }
    case 'page': {
      const { type, ...p } = props;
      return <Page {...p} />;
    }
  }

  return null;
};

export default Loader;
