import { PropsWithChildren, useMemo } from 'react';

import clsx from 'clsx';

import Loader from '../Loader';
import { variantsClass, sizesClass, loadingClass } from './Button.classes';
import { ButtonProps } from './Button.types';

const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  disabled,
  icon,
  iconPosition = 'left',
  className,
  fullWidth,
  testId,
  type = 'button',
  isLoading,
  hideChildrenOnLoading = false,
  style,
  as: Component = 'button',
  href,
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  const iconOrLoading = useMemo(() => {
    if (isLoading) {
      return (
        <Loader
          type="spinner"
          className={clsx({
            'w-6 h-6': size === 'medium',
            'w-4 h-4': size === 'small',
          })}
          colorClassName={loadingClass[`${variant}ClassName`]}
        />
      );
    }

    if (icon) {
      return (
        <div className="flex items-center justify-center w-6 h-6">{icon}</div>
      );
    }

    return null;
  }, [icon, isLoading, size, variant]);

  const content = useMemo(() => {
    if (hideChildrenOnLoading && isLoading) {
      return null;
    }

    return children ? <div>{children}</div> : null;
  }, [children, hideChildrenOnLoading, isLoading]);

  return (
    <Component
      onClick={onClick}
      className={clsx(
        'box-border inline-flex flex-shrink-0 items-center justify-center whitespace-nowrap font-primary transition-colors disabled:cursor-not-allowed',
        fullWidth && 'w-full',
        children == null && 'w-12 px-0',
        isLoading && 'pointer-events-none cursor-not-allowed',
        variantsClass[variant],
        sizesClass[`${size}ClassName`],
        className,
      )}
      href={href}
      type={type}
      disabled={disabled}
      data-testid={testId}
      style={style}
    >
      {iconPosition === 'left' && iconOrLoading}
      {content}
      {iconPosition === 'right' && iconOrLoading}
    </Component>
  );
};

export default Button;
