import { FC } from 'react';

import clsx from 'clsx';

import StickyBlock from '@bluecodecom/common/ui/StickyBlock';

import { FooterProps } from './Footer.types';

const Footer: FC<FooterProps> = ({
  children,
  container,
  content,
  className,
  contentClassName = 'flex flex-col space-y-4 px-4 py-4 pb-8',
  classNameScrolled = 'bg-gray-100 dark:bg-gray-600',
  classNameScrolling = 'bg-gray-0 dark:bg-gray-500 shadow-top dark:shadow-darkTop',
}) => (
  <StickyBlock
    className={clsx('z-40 transition-all', className)}
    classNameScrolled={classNameScrolled}
    classNameScrolling={classNameScrolling}
    container={container}
    content={content}
  >
    <div className={contentClassName}>{children}</div>
  </StickyBlock>
);

export default Footer;
