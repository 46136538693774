import { trackingService as trackingServiceBase } from '@bluecodecom/tracking';

import { EventName, TrackingEvents } from './types';

class TrackingService {
  private static instance: TrackingService;

  private sdk_host = '';

  public static getInstance() {
    if (!TrackingService.instance) {
      TrackingService.instance = new TrackingService();
    }

    return TrackingService.instance;
  }

  public init(token = '', id = '', sdkHost = '') {
    this.sdk_host = sdkHost;
    trackingServiceBase.init(token, id);
  }

  public trackEvent<T extends EventName>(
    event: T,
    ...params: TrackingEvents[T] extends null
      ? [undefined?]
      : [TrackingEvents[T]]
  ) {
    trackingServiceBase.trackEvent(event, {
      ...params?.[0],
      app_version: process.env['NX_PUBLIC_APP_VERSION'],
      sdk_host: this.sdk_host,
    });
  }
}

const trackingService = TrackingService.getInstance();

export default trackingService;
