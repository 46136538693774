import { cloneElement, isValidElement } from 'react';

import clsx from 'clsx';

import { IconProps } from './Icon.types';

const Icon = ({ isActive, icon, colorClassName }: IconProps) => (
  <div
    className={clsx(
      'ml-1 flex h-6 w-6 items-center justify-center rounded first:ml-0',
      colorClassName ||
        (isActive
          ? 'bg-blue-600 text-gray-0 dark:bg-gray-0 dark:text-blue-600'
          : 'border border-blue-600 text-blue-600 dark:border-gray-0 dark:text-gray-0'),
    )}
  >
    {isValidElement(icon) &&
      // TS 4.8+ hack
      cloneElement(icon as any, { width: 16, height: 16 })}
  </div>
);

export default Icon;
