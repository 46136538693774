import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import List from '@bluecodecom/common/ui/List';
import Typo from '@bluecodecom/common/ui/Typo';

import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';
import { configService } from '@bluecodecom/bank-search-webview/features/config';

import Empty from './Empty';
import Item from './Item';
import NotEligible from './NotEligible';
import Partners from './Partners';

const PARTNERS_SDK_HOSTS = ['BLUECODE', 'ONEFOOTBALL', 'MOBILEPOCKET'];

const BanksList = () => {
  const { t } = useTranslation();
  const {
    banks,
    search,
    isInitialLoading,
    isLoading,
    isParentSelected,
    currentCountry,
  } = banksHooks.useBanksContext();

  const canShowPartners = useMemo(
    () =>
      PARTNERS_SDK_HOSTS.includes(configService.config.sdk_host || '') &&
      ['AT', 'DE'].includes(currentCountry?.toString() || ''),
    [currentCountry],
  );

  const title = useMemo(() => {
    if (isParentSelected && !search.length) {
      return null;
    }

    if (search.length) {
      return t('banks.title.searching');
    }

    return t('banks.title.default');
  }, [isParentSelected, search.length, t]);

  if (!isInitialLoading) {
    if (!banks && !isLoading) {
      return canShowPartners ? <Partners /> : <NotEligible />;
    }

    if (banks && !banks.length) {
      return <Empty />;
    }
  }

  return (
    <div className="flex flex-col flex-1 ">
      <div className="flex flex-col px-4 pb-8 space-y-2">
        {title && (
          <Typo
            loadingClassName="w-1/4"
            isLoading={isInitialLoading}
            className=""
          >
            {title}
          </Typo>
        )}

        <List isDense loadingItemsCount={5} isLoading={isInitialLoading}>
          {banks?.map((b, idx) => <Item key={idx} bank={b} />)}
        </List>
      </div>
    </div>
  );
};

export default BanksList;
