import clsx from 'clsx';

import Indicator from '../Indicator';
import { FullScreenProps } from './FullScreen.types';

const FullScreen = ({
  className,
  indicatorIconClassName,
  indicatorClassName,
  indicatorSize,
  absolutePosition,
  image,
  testId = 'loader-fullscreen',
}: FullScreenProps) => (
  <div
    data-testid={testId}
    className={clsx(
      'top-0 left-0 right-0 bottom-0 z-20 flex animate-appear flex-col items-center justify-center bg-gray-0 opacity-0 dark:bg-gray-500',
      absolutePosition ? 'absolute' : 'fixed',
      className,
    )}
  >
    <Indicator
      image={image}
      size={indicatorSize}
      className={indicatorClassName}
      iconClassName={indicatorIconClassName}
    />
  </div>
);

export default FullScreen;
