import { initI18n } from '@bluecodecom/common/i18n';

import de from './locales/de';
import en from './locales/en';

export const RESOURCES = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
} as const;

initI18n(RESOURCES, {
  interpolation: {
    escapeValue: false,
  },
});
