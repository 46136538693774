import { jwtDecode } from 'jwt-decode';

import { JwtPayload } from './types';

class JwtService {
  private static instance: JwtService;

  private _jwt = '';
  private _decoded!: JwtPayload;

  get decoded() {
    return this._decoded;
  }

  get jwt() {
    return this._jwt;
  }

  public static getInstance() {
    if (!JwtService.instance) {
      JwtService.instance = new JwtService();
    }

    return JwtService.instance;
  }

  public decode(jwt: string): Promise<JwtPayload> {
    return new Promise<JwtPayload>((resolve) => {
      this._jwt = jwt;
      this._decoded = jwtDecode<JwtPayload>(jwt);

      resolve(this._decoded);
    });
  }
}

const jwtService = JwtService.getInstance();

export default jwtService;
