import clsx from 'clsx';

import Typo from '../../../Typo';
import { TitleProps } from './Title.types';

const Title = ({
  children,
  className,
  color,
  size,
  weight = '500',
  isTruncated,
}: TitleProps) => (
  <Typo
    color={color}
    size={size}
    className={clsx(
      isTruncated && 'overflow-hidden text-ellipsis whitespace-nowrap',
      className,
    )}
    weight={weight}
  >
    {children}
  </Typo>
);

export default Title;
