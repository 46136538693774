import { ConfigCountry } from '../../config';
import { BankItem, BanksResult } from './types';

class BanksService {
  private static instance: BanksService;

  private _bankSearchUrl = '';
  private _bankSearchBranchesUrl = '';
  private _jwt = '';

  public controller: AbortController | null = null;

  public get bankSearchUrl() {
    return this._bankSearchUrl;
  }

  public get bankSearchBranchesUrl() {
    return this._bankSearchBranchesUrl;
  }
  public set bankSearchBranchesUrl(value) {
    this._bankSearchBranchesUrl = value;
  }

  public get jwt() {
    return this._jwt;
  }

  public static getInstance() {
    if (!BanksService.instance) {
      BanksService.instance = new BanksService();
    }

    return BanksService.instance;
  }

  private async fetchData<T>(url = '', abortable = false) {
    this.controller = new AbortController();

    const response = await fetch(
      (this.bankSearchBranchesUrl || this._bankSearchUrl) + url,
      {
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${this.jwt}`,
        },
        signal: abortable ? this.controller.signal : null,
      },
    );
    const data = await response.json().then((data) => data);

    if (response.ok) {
      return data as T;
    } else {
      throw new Error(data.code);
    }
  }

  public async search(
    country = '',
    query = '',
    abortable = false,
  ): Promise<BankItem[] | null> {
    const shouldFetchBanks = (country as ConfigCountry) !== 'EU';
    if (!shouldFetchBanks) {
      return Promise.resolve([]);
    }

    const result = await (async () => {
      if (!query) {
        return await this.fetchData<BanksResult | BankItem[]>(
          `/?country=${country}`,
          abortable,
        );
      }

      return await this.fetchData<BanksResult | BankItem[]>(
        `/?q=${query}&country=${country}`,
        abortable,
      );
    })();

    if (Array.isArray(result)) {
      return result;
    }

    return result.results;
  }

  public getBankKey(bank: BankItem) {
    return `${bank.bic}-${bank.blz}`;
  }

  public async init(bankSearchUrl = '', jwt?: string | null) {
    this._bankSearchUrl = bankSearchUrl;
    this._jwt = jwt || '';
  }
}

const banksService = BanksService.getInstance();

export default banksService;
