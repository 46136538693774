import {
  useRef,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';

import { ImageSize, SupportedAspectRatios } from './Image.types';

export const useLoading = (src: string) => {
  const [isLoading, setLoading] = useState(true);

  const onComplete = useCallback(() => setLoading(false), []);

  const onError = useCallback(() => setLoading(false), []);

  useLayoutEffect(() => {
    setLoading(true);
  }, [src]);

  return { isLoading, onComplete, onError };
};

export const useAspectRatio = (
  aspectRatio: SupportedAspectRatios,
  src: string,
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [size, setSize] = useState<ImageSize | undefined>(undefined);

  const calculateSize = useCallback(
    (width: number) => {
      switch (aspectRatio) {
        case '3:4':
          return { width: `${width}px`, height: `${(width * 4) / 3}px` };
        case '16:9':
          return { width: `${width}px`, height: `${(width * 9) / 16}px` };
        case '1:1':
          return { width: `${width}px`, height: `${width}px` };
        default:
          return;
      }
    },
    [aspectRatio],
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setSize(calculateSize(containerRef.current.clientWidth));
  }, [containerRef, aspectRatio, calculateSize, src]);

  return { containerRef, size };
};
