import * as Sentry from '@sentry/react';

import { DeploymentTarget } from '@bluecodecom/types';

import { SentryEnvironment, SentryInitProps } from './types';

export class SentryService {
  private static instance: SentryService;

  public static getInstance(): SentryService {
    if (!SentryService.instance) {
      SentryService.instance = new SentryService();
    }

    return SentryService.instance;
  }

  private getSentryEnvironment(
    deploymentTarget: DeploymentTarget,
  ): [SentryEnvironment, number] {
    switch (deploymentTarget) {
      case 'local':
        return ['LOCAL', 1];
      case 'bcx-dev':
      case 'bci-dev':
        return ['DEV', 1];
      case 'bcx-int':
      case 'bci-int':
      case 'ngx-int':
      case 'ngc-int':
        return ['INT', 1];
      case 'bcx-prod':
      case 'ngx-prod':
      case 'bci-prod':
      case 'ngc-prod':
        return ['PROD', 0.001];
    }

    throw new Error('Invalid deployment target');
  }

  public init({
    dsn = '',
    deploymentTarget = 'local',
    release = '0.0.1',
    options = {},
  }: SentryInitProps) {
    const [environment, tracesSampleRate] =
      this.getSentryEnvironment(deploymentTarget);

    if (!dsn || environment === 'LOCAL') {
      return;
    }

    Sentry.init({
      ...options,
      dsn,
      environment,
      tracesSampleRate,
      release,
      integrations: [Sentry.browserTracingIntegration()],
      ignoreErrors: [/^ChunkLoadError$/],
    });
  }

  public setUser(id: string) {
    Sentry.setUser({ id });
  }
}

const sentryService = SentryService.getInstance();

export default sentryService;
