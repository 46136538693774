import { useContext } from 'react';

import { ConfigContext } from '../../providers/Config';

const useConfigContext = () => {
  const ctx = useContext(ConfigContext);

  if (!ctx) {
    throw new Error(
      'The useConfigContext hook must be used within a ConfigContext.Provider',
    );
  }

  return ctx;
};

export default useConfigContext;
