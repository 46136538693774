import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { LoaderComponents } from '@bluecodecom/loader';

import { nativeService } from '@bluecodecom/common/services/native';
import Asset from '@bluecodecom/common/ui/Asset';
import Button from '@bluecodecom/common/ui/Button';
import Footer from '@bluecodecom/common/ui/Footer';
import Layout from '@bluecodecom/common/ui/Layout';
import Typo from '@bluecodecom/common/ui/Typo';

import { trackingService } from '@bluecodecom/bank-search-webview//features/tracking';
import {
  banksHooks,
  banksService,
} from '@bluecodecom/bank-search-webview/features/banks';
import { configService } from '@bluecodecom/bank-search-webview/features/config';

import BackButton from './BackButton';
import BanksList from './BanksList';
import Countries from './Countries';
import Search from './Search';

const Root = () => {
  const {
    hasCountryPicker,
    selectedBankName,
    isSearchingFocused,
    search,
    currentCountry,
  } = banksHooks.useBanksContext();

  const { t } = useTranslation();

  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const isEuSelected = useMemo(() => currentCountry === 'EU', [currentCountry]);

  const showHeader = useMemo(
    () => !isSearchingFocused && !search,
    [isSearchingFocused, search],
  );

  const translate = useMemo(
    () => (showHeader ? '0px' : `-${textRef.current?.offsetHeight || 0}px`),
    [showHeader],
  );

  const handleOnboardEuClick = useCallback(() => {
    if (!configService.config.region_eu_bank?.id) {
      return;
    }

    trackingService.trackEvent('bank-search bank-selected eu', {
      id: configService.config.region_eu_bank.id,
    });

    document.location = `https://app.${configService.domain}/gateway/onboard/${configService.config.region_eu_bank.id}?jwt=${banksService.jwt}`;
  }, []);

  useEffect(() => {
    if (!isSearchingFocused) {
      document.body.style.height = '100vh';
      return;
    }

    const updateHeight = () => {
      document.documentElement.scrollTop = 1;

      if (document.documentElement.scrollTop === 0) {
        return;
      }

      document.documentElement.scrollTop = 0;
      // keyboard was opened
      document.body.style.height = window.visualViewport?.height
        ? `${window.visualViewport?.height}px`
        : 'auto';
    };

    document.addEventListener('scroll', updateHeight, { once: true });
    const t = setTimeout(updateHeight, 100);

    return () => {
      clearTimeout(t);
    };
  }, [isSearchingFocused]);

  useEffect(() => {
    trackingService.trackEvent('bank-search open');
    nativeService.setOnboardingTrackingEvent('onBankSearchDidLoad');
  }, []);

  return (
    <LoaderComponents.Loaded>
      <Layout scrollContainer={containerRef}>
        <div className="flex flex-col flex-1" ref={setContentRef}>
          <Typo
            ref={textRef}
            size="2xl"
            className={clsx(
              'px-4 pb-4 transition-transform duration-300 will-change-transform',
              selectedBankName ? 'text-left' : 'text-center',
              {
                '-translate-y-full ease-out': !showHeader,
                'translate-y-0 ease-in': showHeader,
              },
            )}
          >
            {selectedBankName || t('PageHeader.title')}
          </Typo>
          <div
            style={{
              transform: `translateY(${translate})`,
              marginBottom: translate,
            }}
            className={clsx(
              'w-full transition-transform duration-300 flex flex-col flex-1 relative',
              showHeader ? 'ease-in' : 'ease-out',
            )}
          >
            <Countries />
            {isEuSelected ? (
              <div className="flex flex-col items-center px-4 pt-2 space-y-3 text-center">
                <Asset
                  name="Bank"
                  isBouncing
                  dropShadow
                  autoResizeViewHeight={30}
                />
                <Typo size="2xl" weight="700">
                  {t('eu.title')}
                </Typo>
                <Typo weight="300">{t('eu.description')}</Typo>
              </div>
            ) : (
              <>
                <Search />
                <div
                  className={clsx(
                    'absolute top-0 z-40 w-full  bg-gray-100 dark:bg-gray-600',
                    hasCountryPicker ? 'h-20' : 'h-5',
                  )}
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 overflow-auto scrollbar-hide">
                  <div
                    className={clsx(
                      'relative flex flex-col',
                      isSearchingFocused || search ? 'space-y-3' : 'space-y-6',
                    )}
                    style={{
                      paddingTop: hasCountryPicker ? '8rem' : '5rem',
                    }}
                  >
                    <BackButton />
                    <BanksList />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isEuSelected && (
          <Footer container={containerRef} content={contentRef}>
            <Button onClick={handleOnboardEuClick}>{t('eu.button')}</Button>
          </Footer>
        )}
      </Layout>
    </LoaderComponents.Loaded>
  );
};

export default Root;
