import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Asset from '@bluecodecom/common/ui/Asset';
import Typo from '@bluecodecom/common/ui/Typo';

const Empty: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 px-4 overflow-auto scrollbar-hide">
      <div className="flex flex-col space-y-3 text-center justify-center">
        <Typo weight="700" size="2xl">
          {t('banks.no-results.title')}
        </Typo>
        <Typo weight="300">{t('banks.no-results.subtitle')}</Typo>
        <Asset
          name="Magnifying Glass"
          dropShadow
          isBouncing
          disableAutoResize
          containerClassName="h-[10rem]"
          imageClassName="!max-h-full w-[100px] h-[100px]"
        />
      </div>
    </div>
  );
};

export default Empty;
