import BaseButton from '../../../Button';
import { ButtonProps } from './Button.types';

const Button = ({
  children,
  size = 'small',
  variant = 'secondary',
}: ButtonProps) => (
  <BaseButton size={size} variant={variant}>
    {children}
  </BaseButton>
);

export default Button;
