import { useCallback } from 'react';

import clsx from 'clsx';

import Loader from '../Loader';
import { useAspectRatio, useLoading } from './Image.hook';
import { ImageProps } from './Image.types';

export const Image = ({
  src,
  alt,
  style = {},
  className,
  imageClassName,
  loaderClassName,
  loaderColorClassName,
  loaderStyle,
  isLoading: incomingIsLoading,
  aspectRatio = '1:1',
  omitAspectRatio,
  onError: incomingOnError,
  onComplete: incomingOnComplete,
}: ImageProps) => {
  const { containerRef, size } = useAspectRatio(aspectRatio, src);
  const { isLoading, onComplete, onError } = useLoading(src);

  const handleError = useCallback(() => {
    incomingOnError?.();
    onError();
  }, [incomingOnError, onError]);

  const handleComplete = useCallback(() => {
    incomingOnComplete?.();
    onComplete();
  }, [incomingOnComplete, onComplete]);

  return (
    <div
      ref={containerRef}
      className={clsx('relative overflow-hidden', className)}
      style={!omitAspectRatio ? { ...size, ...style } : { ...style }}
    >
      <img
        className={clsx(
          'transition-opacity duration-300 absolute w-full h-full ',
          isLoading ? 'opacity-0' : 'opacity-100',
          imageClassName,
        )}
        alt={alt}
        src={src}
        draggable={false}
        onLoad={handleComplete}
        onError={handleError}
      />
      {(isLoading || incomingIsLoading) && (
        <Loader
          type="pulse"
          style={loaderStyle}
          colorClassName={loaderColorClassName}
          className={clsx(
            'absolute top-0 left-0 right-0 bottom-0',
            loaderClassName,
          )}
        />
      )}
    </div>
  );
};

export default Image;
