import List from '@bluecodecom/common/ui/List';

import ForwardIcon from '@bluecodecom/icons/ForwardIcon';

import { banksService } from '@bluecodecom/bank-search-webview/features/banks';
import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';

import { ItemProps } from './Item.types';

const Item = ({ bank }: ItemProps) => {
  const { duplicatedNames, onBankSelect } = banksHooks.useBanksContext();

  const hasDuplication = duplicatedNames.includes(bank.name);

  return (
    <List.Item
      key={banksService.getBankKey(bank)}
      logo={bank.logo && <List.Item.Logo url={bank.logo} />}
      title={<List.Item.Title isTruncated={false}>{bank.name}</List.Item.Title>}
      subtitle={
        hasDuplication ? (
          <List.Item.Subtitle
            isTruncated={false}
          >{`${bank.bic} / ${bank.blz}`}</List.Item.Subtitle>
        ) : (
          ''
        )
      }
      onClick={onBankSelect(bank)}
      icons={<ForwardIcon className="text-gray-600 dark:text-gray-0" />}
    />
  );
};

export default Item;
