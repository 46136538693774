import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';
import i18next from 'i18next';

import { DeploymentTargetBci } from '@bluecodecom/types';

import { sentryService } from '@bluecodecom/sentry';

import { environment } from '@bluecodecom/bank-search-webview/environments/environment';
import { banksService } from '@bluecodecom/bank-search-webview/features/banks';
import { jwtService } from '@bluecodecom/bank-search-webview/features/jwt';
import { trackingService } from '@bluecodecom/bank-search-webview/features/tracking';

import { configService, Config } from '../../service';
import { ConfigContextValue, ConfigProviderProps } from './Config.types';

export const ConfigContext = createContext<ConfigContextValue | null>(null);

const DEPLOYMENT_TARGET_ENV = process.env[
  'NX_PUBLIC_DEPLOYMENT_TARGET'
] as DeploymentTargetBci;

const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [config, setConfig] = useState<Config | null>(null);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const jwt = searchParams.get('jwt');
  const deploymentTarget = useMemo(
    () => (environment.local ? 'local' : DEPLOYMENT_TARGET_ENV || 'bci-dev'),
    [],
  );

  const setupConfig = useCallback(
    async (cfg: Config) => {
      if (!cfg?.bank_search_url) {
        throw new Error('Bank search url not found in config');
      }

      await banksService.init(cfg.bank_search_url, jwt);
    },
    [jwt],
  );

  useEffect(() => {
    if (config) {
      return;
    }

    configService
      .init(deploymentTarget, jwt)
      .then(async (config) => {
        const decoded = await jwtService.decode(jwt || '');
        const configLanguage = decoded?.lang || 'de';

        i18next.changeLanguage(configLanguage);
        dayjs.locale(configLanguage);

        await setupConfig(config);

        trackingService.init(
          config.mixpanel_token || '',
          config.wallet_id || '',
          config.sdk_host || '',
        );

        sentryService.init({
          dsn: process.env['NX_PUBLIC_SENTRY_DSN'],
          deploymentTarget,
          release: process.env['NX_PUBLIC_APP_VERSION'],
        });
        sentryService.setUser(config.wallet_id || '');

        setConfig(config);
      })
      .catch(() => setHasError(true))
      .finally(() => setLoading(false));
  }, [config, deploymentTarget, jwt, setupConfig]);

  if (loading) {
    return null;
  }

  if (pathname === '/config-error') {
    return <>{children}</>;
  }

  if (!config || hasError) {
    return (
      <Navigate
        replace
        to={{ pathname: '/config-error', search: window.location.search }}
      />
    );
  }

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
