import { useContext } from 'react';

import { BanksContext } from '../../providers/Banks';

const useBanksContext = () => {
  const ctx = useContext(BanksContext);

  if (!ctx) {
    throw new Error(
      'The useBanksContext hook must be used within a BanksContext.Provider',
    );
  }

  return ctx;
};

export default useBanksContext;
