import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { LoaderProvider } from '@bluecodecom/loader';

import '@bluecodecom/fonts';

import { ThemeProvider } from '@bluecodecom/common/theme';

import { ConfigProvider } from '@bluecodecom/bank-search-webview/features/config';

import './i18n';
import Root from './pages/Root';

const RootPage = lazy(() => import('./pages/Root'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));
const ConfigErrorPage = lazy(() => import('./pages/ConfigError'));
const OnboardingRootPage = lazy(() => import('./pages/Onboarding/Root'));
const OnboardingSuccessPage = lazy(() => import('./pages/Onboarding/Success'));

createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <LoaderProvider>
      <ThemeProvider observe>
        <ConfigProvider>
          <Suspense>
            <Routes>
              <Route path="/" element={<Root />} />
              <Route path="/config-error" element={<ConfigErrorPage />} />
              <Route path="/:idWithName" element={<RootPage />} />
              <Route path="/onboarding" element={<OnboardingRootPage />} />
              <Route
                path="/onboarding/success"
                element={<OnboardingSuccessPage />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </ConfigProvider>
      </ThemeProvider>
    </LoaderProvider>
  </BrowserRouter>,
);
