import { FC, PropsWithChildren } from 'react';

import clsx from 'clsx';

import ForwardIcon from '@bluecodecom/icons/ForwardIcon';

import Typo from '../../../Typo';
import { LabelProps } from './Label.types';

const Label: FC<PropsWithChildren<LabelProps>> = ({
  children,
  size = 'xs',
  color = 'text-blue-400 dark:text-gray-0',
  className,
  weight,
  withForward,
  isLoading,
  loadingClassName,
}) => (
  <Typo
    size={size}
    color={color}
    className={clsx(className, {
      'flex items-center': withForward,
    })}
    weight={weight}
    isLoading={isLoading}
    loadingClassName={loadingClassName}
  >
    {children}
    {withForward && <ForwardIcon />}
  </Typo>
);

export default Label;
