import {
  ChangeEventHandler,
  createContext,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useDebounce } from 'use-debounce';

import { nativeService } from '@bluecodecom/common/services/native';

import {
  BankItem,
  banksService,
} from '@bluecodecom/bank-search-webview/features/banks';
import {
  ConfigCountry,
  configHooks,
  configService,
} from '@bluecodecom/bank-search-webview/features/config';
import { trackingService } from '@bluecodecom/bank-search-webview/features/tracking';

import { DefaultBanksByCountry, BanksContextValue } from './Banks.types';

export const BanksContext = createContext<BanksContextValue | null>(null);

const SEARCH_DEBOUNCE = 300;

const BanksProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { config } = configHooks.useConfigContext();
  const { idWithName } = useParams<{ idWithName: string }>();

  const [banks, setBanks] = useState<BankItem[] | null>([]);
  const [currentCountry, setCurrentCountry] = useState<ConfigCountry>('NONE');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearchingFocused, setIsSearchingFocused] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [defaultBanks, setDefaultBanks] = useState<DefaultBanksByCountry>({
    AT: [],
    DE: [],
    EU: [],
    NONE: [],
  });

  const initialLoadingTimeout = useRef(0);

  const [delayedSearch] = useDebounce(search, SEARCH_DEBOUNCE, {
    leading: true,
  });

  const isSearching = search.trim().length > 0;
  const [selectedBankId, selectedBankName] = (idWithName || '').split(':');

  /**
   * Generate a list of duplicated bank names
   * so we will be able to show BIC / BLZ subtitle
   */
  const duplicatedNames = useMemo(() => {
    const names = banks?.map((bank) => bank.name) || [];
    const filteredNames = names.filter(
      (name, index) => names.indexOf(name) !== index,
    );

    return [...new Set(filteredNames)];
  }, [banks]);

  const toggleSearchingFocused = useCallback(() => {
    setHasError(false);
    setIsSearchingFocused((p) => !p);
  }, []);

  const handleCountryChange = useCallback((country: ConfigCountry) => {
    setCurrentCountry(country);
    setIsLoading(true);

    trackingService.trackEvent('bank-search country changed', {
      country,
    });
  }, []);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsLoading(true);
      setSearch(e.target.value);
      document.documentElement.scrollTop = 0;
    },
    [],
  );

  const handleClearSearch = useCallback(() => {
    setSearch('');
    setHasError(false);
  }, []);

  const handleBankWithBranchesSelect = useCallback(
    (bank: BankItem) => {
      nativeService.setOnboardingTrackingEvent(
        'onBankSearchUserDidSelectIssuer',
      );

      if (!bank.branches) {
        return;
      }

      setSearch('');
      setIsInitialLoading(true);

      nativeService.setOnboardingTrackingEvent(
        'onBankSearchRedirectToExternalProvider',
      );

      navigate({
        pathname: `/${bank.id}:${bank.name}`,
        search: window.location.search,
      });
    },
    [navigate],
  );

  const handleBankSelect = useCallback(
    (bank: BankItem): MouseEventHandler<HTMLDivElement> =>
      () => {
        if (bank.branches) {
          return handleBankWithBranchesSelect(bank);
        }

        setHasError(false);

        nativeService.setOnboardingTrackingEvent(
          'onBankSearchUserDidSelectIssuer',
        );

        trackingService.trackEvent('bank-search bank-selected confirmed', {
          bank: bank.name,
          bic: bank.bic,
        });

        if (!search && !selectedBankId) {
          trackingService.trackEvent('bank-search popular bank', {
            bank: bank.name,
            bic: bank.bic,
          });
        }

        nativeService.setOnboardingTrackingEvent(
          'onBankSearchRedirectToExternalProvider',
        );

        document.location =
          bank.bic === 'BLAUATXXXXX'
            ? `${
                window.location.hostname === 'localhost'
                  ? 'http://localhost:3000'
                  : `https://bank-search.${configService.domain}`
              }/onboarding?jwt=${banksService.jwt}`
            : `https://app.${configService.domain}/gateway/onboard/${bank.id}?jwt=${banksService.jwt}`;
      },
    [handleBankWithBranchesSelect, search, selectedBankId],
  );

  const handleBackClick = useCallback(() => {
    setSearch('');
    setBanks(defaultBanks[currentCountry]);
    navigate({ pathname: '/', search: window.location.search });
  }, [currentCountry, defaultBanks, navigate]);

  /**
   * Multiple steps of searching
   * 1. No search value - clear list
   * 2. Only one character provided - display prefetched list of banks for each letter
   * 3. Use fetch to search multiple characters
   */
  useEffect(() => {
    if (!selectedBankId) {
      banksService.bankSearchBranchesUrl = '';
    } else {
      banksService.bankSearchBranchesUrl = [
        banksService.bankSearchUrl,
        selectedBankId,
        'branches',
      ].join('/');
    }

    banksService?.controller?.abort();

    if ((!delayedSearch || !isSearching) && !selectedBankId) {
      setBanks(defaultBanks[currentCountry]);
      setIsLoading(false);
      return;
    }

    window.clearTimeout(initialLoadingTimeout.current);

    banksService
      .search(currentCountry, delayedSearch.toLowerCase(), true)
      .then((banks) => {
        setBanks(banks);

        if (delayedSearch) {
          trackingService.trackEvent('bank-search input', {
            q: delayedSearch,
          });
        }

        if (!banks) {
          trackingService.trackEvent('bank-search not eligible', {
            q: delayedSearch,
          });
        } else if (!banks?.length) {
          trackingService.trackEvent('bank-search no results', {
            q: delayedSearch,
          });
        }

        setIsLoading(false);

        initialLoadingTimeout.current = window.setTimeout(() => {
          setIsInitialLoading(false);
        }, 300);
      })
      .catch((e: Error) => {
        setIsInitialLoading(false);

        if (e.name === 'AbortError') {
          return;
        }

        trackingService.trackEvent('bank-search error', {
          Code: e.message,
          q: delayedSearch,
        });

        setHasError(true);
        setIsLoading(false);
      });
  }, [
    currentCountry,
    defaultBanks,
    delayedSearch,
    isSearching,
    selectedBankId,
  ]);

  useEffect(() => {
    if (currentCountry !== 'NONE') {
      return;
    }

    if (config.allowed_countries?.length === 1) {
      return setCurrentCountry(config.allowed_countries[0]);
    }

    setCurrentCountry(config.preferred_country || 'DE');
  }, [config.allowed_countries, config.preferred_country, currentCountry]);

  useEffect(() => {
    setSearch('');
  }, [selectedBankId]);

  /**
   * Fetch default banks
   */
  useEffect(() => {
    if (currentCountry === 'NONE') {
      return;
    }

    const fetchDefaults = async () => {
      if (!config.allowed_countries) {
        const ccBanks = await banksService.search(currentCountry);
        setDefaultBanks((p) => ({ ...p, [currentCountry]: ccBanks }));
        return false;
      }

      const allCountries = await Promise.all(
        config.allowed_countries.map(async (country) => {
          const banks = await banksService.search(country, '', false);

          return { [country]: banks };
        }),
      );

      setDefaultBanks((p) => ({
        ...p,
        ...allCountries.reduce((p, acc) => {
          acc = { ...acc, ...p };
          return acc;
        }, {}),
      }));

      return false;
    };

    fetchDefaults().then((isLoading) => setIsInitialLoading(isLoading));
  }, [config.allowed_countries, currentCountry]);

  if (hasError) {
    return (
      <Navigate
        to={{ pathname: '/config-error', search: window.location.search }}
      />
    );
  }

  return (
    <BanksContext.Provider
      value={{
        search,
        banks,
        duplicatedNames,
        isLoading,
        isInitialLoading,
        isSearchingFocused,
        hasError,
        currentCountry,
        isParentSelected: !!selectedBankId,
        selectedBankName,
        hasCountryPicker:
          config.allowed_countries?.length === 2 && !selectedBankId,
        toggleSearchingFocused,
        onBankSelect: handleBankSelect,
        onClearSearch: handleClearSearch,
        onSearchChange: handleSearchChange,
        onCountryChange: handleCountryChange,
        onBackClick: handleBackClick,
      }}
    >
      {children}
    </BanksContext.Provider>
  );
};

export default BanksProvider;
