import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { StickyBlockProps } from './StickyBlock.types';

const calcPercent = (p?: HTMLElement | null) => {
  if (!p) return 0;

  return Math.min(100, (p.scrollTop / (p.scrollHeight - p.offsetHeight)) * 100);
};

const StickyBlock = ({
  container,
  content,
  children = null,
  className,
  classNameScrolled,
  classNameScrolling,
  scrollThreshold = 0,
  stickTo = 'bottom',
  style,
  styleScrolling,
  styleScrolled,
  renderOnlyWhenScrollAvailable,
}: StickyBlockProps) => {
  const isStickBottom = stickTo === 'bottom';
  // Assume that there is nothing to scroll
  // Check if later in useEffect
  const [progress, setProgress] = useState(100);
  const reachedScroll = isStickBottom
    ? progress >= 100 - scrollThreshold
    : progress - scrollThreshold <= 0;
  const [contentHeight, setContentHeight] = useState(
    content?.clientHeight || 0,
  );

  const [hasScrolling, setHasScrolling] = useState(false);

  useEffect(() => {
    const parent = container.current;

    if (!parent) {
      return;
    }

    // When there is nothing to scroll do nothing
    if (parent.scrollHeight - parent.offsetHeight <= 0) {
      setHasScrolling(false);
      setProgress(100);
      return;
    }

    let scheduledAnimationFrame = false;

    const handleScroll = () => {
      if (scheduledAnimationFrame) {
        return;
      }

      scheduledAnimationFrame = true;

      requestAnimationFrame(() => {
        setProgress(calcPercent(parent));
        setHasScrolling(true);
        scheduledAnimationFrame = false;
      });
    };

    // Run on mount so it shows for initial render the
    // border if there is anything to scroll
    handleScroll();

    parent.addEventListener('scroll', handleScroll);

    return () => parent.removeEventListener('scroll', handleScroll);
  }, [container, contentHeight]);

  useEffect(() => {
    if (!content) {
      return;
    }

    const ob = new ResizeObserver(([entry]) => {
      setContentHeight(entry?.contentRect?.height || 0);
    });

    ob.observe(content);

    return () => {
      ob.unobserve(content);
    };
  }, [content]);

  const styles = {
    ...style,
    ...(reachedScroll ? { ...styleScrolled } : { ...styleScrolling }),
  };

  if (!hasScrolling && renderOnlyWhenScrollAvailable) {
    return null;
  }

  return (
    <div
      style={styles}
      className={clsx(
        'sticky',
        isStickBottom ? 'bottom-0' : 'top-0',
        className,
        reachedScroll
          ? classNameScrolled
          : hasScrolling
            ? classNameScrolling
            : '',
      )}
    >
      {children}
    </div>
  );
};

export default StickyBlock;
