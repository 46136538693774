import { Children, FC, useMemo } from 'react';

import clsx from 'clsx';

import notEmpty from '@bluecodecom/utils/notEmpty';

import Item from './Item';
import SelectionIndicator from './SelectionIndicator';
import TabsContext from './Tabs.context';
import { TabsComposition, TabsProps } from './Tabs.types';

export const Tabs: FC<TabsProps> & TabsComposition = ({
  children,
  selected,
  loading = false,
  className,
  containerClassName,
  heightClassName = 'h-10',
  isDense = false,
}) => {
  const nonEmptyChildren = useMemo(() => {
    return children.filter(notEmpty);
  }, [children]);

  const selectedIndex = Children.map(
    nonEmptyChildren,
    (child) => child.props.name,
  ).findIndex((name) => name === selected);

  return (
    <TabsContext.Provider
      value={{
        selected,
        itemCount: Children.count(nonEmptyChildren),
        selectedIndex,
        loading,
        isDense,
      }}
    >
      <div
        className={clsx(
          'shadow-insetMd dark:shadow-darkInsetMd rounded-2xl bg-gray-100 p-1 dark:bg-gray-600',
          heightClassName,
          className,
        )}
      >
        <div
          className={clsx(
            'relative flex h-full',

            containerClassName,
          )}
        >
          <SelectionIndicator />
          <div className={clsx('flex w-full', !isDense && 'space-x-2')}>
            {nonEmptyChildren}
          </div>
        </div>
      </div>
    </TabsContext.Provider>
  );
};

Tabs.Item = Item;

export default Tabs;
