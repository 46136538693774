import { FC } from 'react';

import clsx from 'clsx';

import { AssetProps } from './Asset.types';

const Asset: FC<AssetProps> = ({
  bucket = 'images.bluecode.com',
  prefix = 'Illustrations',
  name = '',
  testId,
  dropShadow,
  isBouncing,
  containerClassName,
  imageClassName,
  shadowClassName,
  disableAutoResize,
  autoResizeViewHeight = 30,
  withTopMargin,
  url = '',
  ...imgProps
}) => (
  <div
    className={clsx(
      'flex flex-col items-center space-y-3 justify-self-center',
      withTopMargin && 'mt-[5vh]',
      containerClassName,
    )}
    style={{
      height: !disableAutoResize ? `${autoResizeViewHeight}vh` : undefined,
    }}
  >
    <img
      data-testid={testId}
      draggable={false}
      src={url || `https://${bucket}/${prefix}/${name}.webp`}
      alt={name}
      className={clsx(
        'max-h-48 min-h-[4rem]',
        isBouncing && 'animate-bounce-asset',
        imageClassName,
        {
          'h-[70%] max-w-full': !disableAutoResize && dropShadow,
        },
      )}
      {...imgProps}
    />
    {dropShadow && (
      <div
        className={clsx(
          'h-[30%] w-full bg-asset-shadow-light bg-contain bg-center bg-no-repeat dark:bg-asset-shadow-dark',
          isBouncing && 'animate-shrink-asset-shadow',
          shadowClassName,
        )}
      />
    )}
  </div>
);

export default Asset;
