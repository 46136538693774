import { createContext } from 'react';

export const TabsContext = createContext({
  selected: '',
  itemCount: 0,
  selectedIndex: -1,
  loading: false,
  isDense: false,
});

export default TabsContext;
