import { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import Image from '../../../Image';
import { LogoProps } from './Logo.types';

const Logo = ({
  url,
  icon,
  isLoading,
  iconWrapperClassName,
  borderClassName,
  borderColorClassName = 'border-gray-200 dark:border-gray-400',
  containerSizeClassName = 'h-10 w-10',
  className,
  forceBackground,
  omitAspectRatio,
  borderRadius = 8,
  backgroundClassName,
  backgroundColorClassName = 'bg-blue-400 dark:bg-gray-0',
}: LogoProps) => {
  const [src, setSrc] = useState(url);

  const handleImageError = useCallback(() => setSrc(''), []);

  const showBackground = useMemo(() => {
    return forceBackground || (!src && !isLoading);
  }, [forceBackground, isLoading, src]);

  const content = useMemo(() => {
    if (src) {
      return (
        <Image
          src={src}
          omitAspectRatio={omitAspectRatio}
          imageClassName="overflow-hidden object-contain"
          loaderStyle={{
            borderRadius: borderRadius + 2,
          }}
          className="w-full h-full !absolute z-20"
          style={{
            borderRadius: borderRadius + 2,
          }}
          onError={handleImageError}
        />
      );
    }

    return (
      <div
        className={clsx(
          'z-20 flex items-center justify-center',
          iconWrapperClassName,
        )}
      >
        {icon}
      </div>
    );
  }, [
    borderRadius,
    handleImageError,
    icon,
    iconWrapperClassName,
    omitAspectRatio,
    src,
  ]);

  useEffect(() => {
    setSrc(url);
  }, [url]);

  if (!content) {
    return null;
  }

  return (
    <div
      className={clsx(
        'relative mr-1 flex items-center justify-center overflow-hidden flex-shrink-0',
        'text-gray-0 dark:text-blue-400',
        containerSizeClassName,
        className,
      )}
    >
      {showBackground && (
        <div
          className={clsx(
            'absolute top-0 left-0 right-0 bottom-0 z-10',
            backgroundColorClassName,
            backgroundClassName,
          )}
          style={{
            borderRadius: borderRadius + 2,
          }}
        />
      )}
      {content}
      <div
        className={clsx(
          'absolute top-0 left-0 right-0 bottom-0 z-30 border',
          borderColorClassName,
          borderClassName,
        )}
        style={{
          borderRadius,
        }}
      />
    </div>
  );
};

export default Logo;
