import { FC, PropsWithChildren, useMemo } from 'react';

import clsx from 'clsx';

import { nativeService } from '@bluecodecom/common/services/native';

import { LayoutProps } from './Layout.types';

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  scrollContainer,
  outsideContainerComponent,
  gradientComponent,
  navbarComponent,
  forceGradient = false,
  disableGradient,
  disableFullHeight = false,
  containerClassName,
  backgroundClassName = 'bg-gray-100 dark:bg-gray-600',
  containerId,
  testId,
}) => {
  const showGradient = useMemo(
    () =>
      !disableGradient &&
      (forceGradient ||
        (nativeService.isNativeEnvironment() &&
          !nativeService.isSdkAtLeast('7.0.0'))),
    [disableGradient, forceGradient],
  );

  const gradient = useMemo(() => {
    if (!showGradient) {
      return null;
    }

    if (gradientComponent) {
      return gradientComponent;
    }

    return (
      <div className="h-4 left-0 w-full top-0 fixed z-10 bg-[linear-gradient(0deg,_transparent_0%,#FFF_100%)] dark:bg-[linear-gradient(180deg,_#1F1F1F_0%,_transparent_100%)]" />
    );
  }, [gradientComponent, showGradient]);

  return (
    <>
      {gradient}
      {navbarComponent}
      <div
        data-testid={testId}
        className={clsx(
          'absolute top-0 left-0 right-0 bottom-0 flex overflow-auto scrollbar-hide',
          backgroundClassName,
        )}
      >
        <div className="flex-1 animate-appear animation-delay-none">
          {outsideContainerComponent}
          <div
            ref={scrollContainer}
            className={clsx(
              'relative flex flex-col overflow-auto scrollbar-hide',
              {
                'pt-4': showGradient && !navbarComponent && !gradientComponent,
                'h-full': !disableFullHeight,
              },
              containerClassName,
            )}
            id={containerId}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
