import { forwardRef, PropsWithChildren } from 'react';

import clsx from 'clsx';

import Loader from '../Loader';
import { TypoProps } from './Typo.types';

const Typo = forwardRef<HTMLDivElement, PropsWithChildren<TypoProps>>(
  (
    {
      as: Component = 'div',
      className = '',
      children,
      size = 'base',
      weight = '500',
      fontFamily = 'primary',
      color = 'text-gray-600 dark:text-gray-0',
      testId,
      isLoading,
      loadingClassName = '',
      style,
      onClick,
    },
    ref,
  ) => (
    <Component
      ref={ref}
      data-testid={testId}
      style={style}
      onClick={onClick}
      className={clsx({
        // default
        [color]: true,
        // font families
        'font-primary': fontFamily === 'primary',
        // font weights
        'font-light': weight === '300',
        'font-medium': weight === '500',
        'font-bold': weight === '700',
        // sizes
        'text-xs': size === 'xs',
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'text-3xl': size === '3xl',
        'text-4xl': size === '4xl',
        'text-5xl': size === '5xl',
        'text-6xl': size === '6xl',
        'text-8xl': size === '8xl',
        'text-9xl': size === '9xl',
        // overrides
        [className]: true,
        [loadingClassName]: isLoading,
        'relative rounded-lg': isLoading,
      })}
    >
      {isLoading ? (
        <>
          &nbsp;
          <Loader
            type="pulse"
            className="absolute top-0 left-0 right-0 bottom-0 rounded-lg"
          />
        </>
      ) : (
        children
      )}
    </Component>
  ),
);

export default Typo;
