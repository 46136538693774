import { FC } from 'react';

import clsx from 'clsx';

import Indicator from '../Indicator';
import { PageProps } from './Page.types';

const Page: FC<PageProps> = ({ className, image }) => (
  <div
    className={clsx(
      'absolute top-0 left-0 right-0 bottom-0 z-20 flex items-center justify-center pointer-events-none',
      className,
    )}
  >
    <Indicator image={image} />
  </div>
);

export default Page;
