import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="search-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.5103 11.505c0 3.8686-3.1363 7.0049-7.0051 7.0049-3.869 0-7.0052-3.1363-7.0052-7.0049C4.5 7.6363 7.6363 4.5 11.5052 4.5c3.8688 0 7.0051 3.1363 7.0051 7.005Zm-1.5446 6.5208c-1.4774 1.2384-3.3819 1.9841-5.4605 1.9841C6.8079 20.0099 3 16.2021 3 11.505 3 6.8078 6.8079 3 11.5052 3c4.6972 0 8.5051 3.8078 8.5051 8.505 0 2.0785-.7456 3.9828-1.984 5.4602l2.7542 2.7541a.75.75 0 0 1-1.0607 1.0606l-2.7541-2.7541Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSearchIcon);
export default Memo;
