import { FC } from 'react';

import clsx from 'clsx';

import Item from './Item';
import { ActiveClasses, ListComposition, ListProps } from './List.types';
import Loading from './Loading';

const ACTIVE_CLASSES: ActiveClasses = {
  primary: 'bc-list-active-primary',
  cashback: 'bc-list-active-cashback',
};

const List: FC<ListProps> & ListComposition = ({
  children,
  isDense,
  isActive,
  isLoading,
  className,
  style,
  loadingItemsCount,
  loadingItemSize,
  loadingHideLogo,
  testId,
  notInteractive,
  activeType = 'primary',
}) => (
  <div
    style={style}
    data-testid={testId}
    className={clsx(
      'bc-list',
      isDense ? 'bc-list-dense' : 'bc-list-normal',
      isActive && ACTIVE_CLASSES[activeType],
      notInteractive && 'bc-list-not-interactive',
      className,
    )}
  >
    {isLoading ? (
      <Loading
        size={loadingItemSize}
        itemsCount={loadingItemsCount}
        hideLogo={loadingHideLogo}
      />
    ) : (
      children
    )}
  </div>
);

List.Item = Item;

export default List;
