import useTabs from '../useTabs';

export const SelectionIndicator = () => {
  const { selectedIndex, itemCount, loading, isDense } = useTabs();

  if (selectedIndex < 0 || loading) {
    return null;
  }

  const width = 100 / itemCount;
  const gapSize = isDense ? 0 : 8; // should equal to the parent gap in flex
  const spacingOffset = gapSize * (itemCount - 1) * (1 / itemCount);

  return (
    <div
      className="bc-tabs-selected-indicator-shadow absolute h-full w-full origin-top-left rounded-[12px] bg-gray-0 transition-transform duration-200 dark:bg-gray-500"
      style={{
        width: `calc(${width}% - ${spacingOffset}px)`,
        transform: `translateX(calc(${100 * selectedIndex}% + ${
          gapSize * selectedIndex
        }px))`,
      }}
    ></div>
  );
};

export default SelectionIndicator;
