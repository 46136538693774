import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgForwardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="forward-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m16 6 5.6464 5.6464a.5.5 0 0 1 0 .7072L16 18"
    />
  </svg>
);
const Memo = memo(SvgForwardIcon);
export default Memo;
