import { BanksProvider } from '@bluecodecom/bank-search-webview/features/banks';

import Root from './Root';

const RootContainer = () => (
  <BanksProvider>
    <Root />
  </BanksProvider>
);

export default RootContainer;
