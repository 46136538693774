const primaryVariant = {
  baseClassName: 'bg-blue-400 border-blue-400 text-gray-0',
  disabledClassName:
    'disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-300',
  hoverClassName: 'hover:bg-blue-500 hover:border-blue-500 hover:text-blue-100',
  activeClassName:
    'active:bg-blue-600 active:border-blue-600 active:text-gray-0',
  // DARK
  darkClassName: 'dark:bg-gray-0 dark:border-gray-0 dark:text-blue-400',
  darkDisabledClassName:
    'dark:disabled:bg-gray-500 dark:disabled:border-gray-500 dark:disabled:text-gray-300',
  darkHoverClassName:
    'dark:hover:bg-blue-50 dark:hover:border-blue-50 dark:hover:text-blue-600',
  darkActiveClassName:
    'dark:active:bg-blue-100 dark:active:border-blue-100 dark:active:text-blue-600',
};

const primaryWhiteVariant = {
  baseClassName: 'bg-gray-0 border-gray-0 text-gray-600',
  disabledClassName:
    'disabled:bg-gray-500 disabled:border-gray-500 disabled:text-gray-300',
  hoverClassName: 'hover:bg-blue-50 hover:border-blue-50 hover:text-blue-600',
  activeClassName:
    'active:bg-blue-100 active:border-blue-100 active:text-blue-600',
};

const primaryDarkVariant = {
  baseClassName: 'bg-gray-500 border-gray-500 text-gray-0',
  disabledClassName:
    'disabled:bg-gray-500 disabled:border-gray-500 disabled:text-gray-300',
  hoverClassName: 'hover:bg-gray-400 hover:border-gray-400 hover:text-gray-0',
  activeClassName:
    'active:bg-gray-600 active:border-gray-600 active:text-gray-0',
};

const secondaryWhiteVariant = {
  baseClassName: 'bg-transparent border-gray-0 text-gray-0',
  disabledClassName:
    'disabled:bg-transparent disabled:border-gray-400 disabled:text-gray-300',
  hoverClassName: 'hover:bg-transparent hover:text-gray-200 hover:bg-gray-500',
  activeClassName:
    'active:bg-transparent active:border-gray-0 active:text-gray-0',
};

const secondaryVariant = {
  baseClassName: 'bg-transparent border-blue-400 text-blue-400',
  disabledClassName:
    'disabled:bg-transparent disabled:border-gray-200 disabled:text-gray-300',
  hoverClassName: 'hover:bg-blue-50 hover:text-blue-600 hover:border-blue-600',
  activeClassName:
    'active:bg-blue-100 active:border-blue-400 active:text-blue-400',
  // DARK
  darkClassName: 'dark:bg-transparent dark:border-gray-0 dark:text-gray-0',
  darkDisabledClassName:
    'dark:disabled:bg-transparent dark:disabled:border-gray-400 dark:disabled:text-gray-300',
  darkHoverClassName:
    'dark:hover:border-gray-200 dark:hover:text-gray-200 dark:hover:bg-gray-500',
  darkActiveClassName:
    'dark:active:bg-gray-400 dark:active:border-gray-0 dark:active:text-gray-0',
};

const tertiaryVariant = {
  baseClassName: 'bg-transparent border-transparent text-blue-400',
  disabledClassName:
    'disabled:bg-transparent disabled:border-transparent disabled:text-gray-300',
  hoverClassName: 'hover:bg-blue-50 hover:border-blue-50 hover:text-blue-600',
  activeClassName:
    'active:bg-blue-100 active:border-blue-100 active:text-blue-400',
  // DARK
  darkClassName: 'dark:text-gray-0',
  darkDisabledClassName:
    'dark:disabled:text-gray-300 dark:disabled:bg-transparent dark:disabled:border-transparent',
  darkHoverClassName:
    'dark:hover:text-gray-0 dark:hover:bg-gray-500 dark:hover:border-gray-500',
  darkActiveClassName:
    'dark:active:bg-gray-400 dark:active:border-gray-400 dark:active:text-gray-200',
};

const destructiveVariant = {
  baseClassName: 'bg-transparent border-red-400 text-red-400',
  disabledClassName:
    'disabled:bg-transparent disabled:border-gray-200 disabled:text-gray-300',
  hoverClassName: 'hover:bg-red-50 hover:border-red-500 hover:text-red-500',
  activeClassName:
    'active:bg-red-100 active:border-red-400 active:text-red-400',
  // DARK
  darkClassName: 'dark:border-red-200 dark:text-red-200',
  darkDisabledClassName:
    'dark:disabled:text-gray-300 dark:disabled:border-gray-400 dark:disabled:bg-gray-500',
  darkHoverClassName:
    'dark:hover:text-red-300 dark:hover:border-red-300 dark:hover:bg-gray-500',
  darkActiveClassName:
    'dark:active:bg-gray-400 dark:active:text-red-200 dark:active:border-red-200',
};

export const loadingClass = {
  primaryClassName: 'stroke-gray-0 dark:stroke-blue-400',
  'primary-whiteClassName': 'stroke-blue-400',
  'primary-darkClassName': 'stroke-gray-0',
  secondaryClassName: 'stroke-blue-400 dark:stroke-gray-0',
  'secondary-whiteClassName': 'stroke-gray-0',
  tertiaryClassName: 'stroke-blue-400 dark:stroke-gray-0',
  destructiveClassName: 'stroke-red-400 dark:stroke-red-200',
};

export const sizesClass = {
  mediumClassName: 'h-12 text-base px-6 border-2 space-x-2 rounded-2xl',
  smallClassName: 'h-8 text-sm px-2 border-2 space-x-1 rounded-xl',
  iconClassName: 'h-8 w-8 border-2 rounded-full',
};

export const variantsClass = {
  primary: Object.values(primaryVariant).join(' '),
  'primary-white': Object.values(primaryWhiteVariant).join(' '),
  'primary-dark': Object.values(primaryDarkVariant).join(' '),
  secondary: Object.values(secondaryVariant).join(' '),
  'secondary-white': Object.values(secondaryWhiteVariant).join(' '),
  tertiary: Object.values(tertiaryVariant).join(' '),
  destructive: Object.values(destructiveVariant).join(' '),
};
