import clsx from 'clsx';

import { PulseProps } from './Pulse.types';

export const Pulse = ({
  children,
  style,
  testId = 'loader-pulse',
  className = 'w-full h-full',
  colorClassName = 'bg-gray-200 dark:bg-gray-400',
}: PulseProps) => {
  return (
    <div
      data-testid={testId}
      style={style}
      className={clsx('animate-pulse', className, !children && colorClassName)}
    >
      {children}
    </div>
  );
};

export default Pulse;
