import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="check-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m6 11.6 3.9257 4.0304a.5.5 0 0 0 .7181-.0018L18 8"
    />
  </svg>
);
const Memo = memo(SvgCheckIcon);
export default Memo;
