import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="close-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m7 7 10 10M7 17 17 7"
    />
  </svg>
);
const Memo = memo(SvgCloseIcon);
export default Memo;
