import times from 'ramda/es/times';

import Loader from '../../Loader';
import Item from '../Item';
import { LoadingProps } from './Loading.types';

const PULSE_LOADER = (
  <Loader
    type="pulse"
    className="absolute top-0 left-0 right-0 bottom-0 z-20 rounded-lg"
  />
);

const Loading = ({
  itemsCount = 1,
  size = 'medium',
  hideLogo,
}: LoadingProps) => (
  <>
    {times(
      (idx) => (
        <Item
          key={idx}
          size={size}
          logo={hideLogo ? null : <Item.Logo isLoading icon={PULSE_LOADER} />}
          title={
            <Item.Title className="relative w-1/3 rounded-lg">
              &nbsp;
              {PULSE_LOADER}
            </Item.Title>
          }
          subtitle={
            <Item.Subtitle className="relative w-1/6 rounded-lg">
              &nbsp;
              {PULSE_LOADER}
            </Item.Subtitle>
          }
          label={
            <Item.Label className="relative w-8 overflow-hidden rounded-lg">
              &nbsp;
              {PULSE_LOADER}
            </Item.Label>
          }
        />
      ),
      Math.max(itemsCount || 1, 1),
    )}
  </>
);

export default Loading;
