import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgBluecodeTriangleLightBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    data-testid="bluecode-triangle-light-blue"
    viewBox="0 0 512 603"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M492.5935 333.957c11.3697-6.8632 18.3188-19.1762 18.3188-32.4564 0-13.2801-6.949-25.5931-18.3188-32.4563L58.5903 7.0436C46.8815-.0253 32.273-.2392 20.362 6.4838 8.451 13.2058 1.0845 25.8228 1.0845 39.5v524.0013c0 13.6772 7.3665 26.2942 19.2775 33.0162 11.911 6.723 26.5195 6.509 38.2283-.5599L492.5935 333.957Z"
    />
  </svg>
);
const Memo = memo(SvgBluecodeTriangleLightBlue);
export default Memo;
