const en = {
  BankSearchForm: {
    countries: {
      austria: '🇦🇹 Austria',
      germany: '🇩🇪 Germany',
      eu: '🇪🇺 EU',
    },
    back: 'All banks',
    'banks-not-found': 'No banks were found for your search term.',
    confirm: 'Confirm',
    'not-found': {
      iban: '{{q}} is your IBAN. But first we need the name of the bank or the BIC code of your account.',
      long: 'Try to include only a part of the name.',
      main: 'No supported bank with the name {{q}} was found. ',
      short: 'If this is an abbreviation, try entering the full name.',
    },
    'label-search': 'Name, BLZ or BIC of your bank',
    'children-label-search': 'Search in "{{bank}}"',
  },
  ErrorPage: {
    'config-error':
      'Internal error (missing configuration). Please go back and try again.',
    title: 'Unexpected condition',
  },
  eu: {
    title: 'European Union',
    description:
      'In the next step, start the account verification of your European bank account.',
    button: 'Start account verification',
  },
  errors: {
    'not-found': 'No supported bank with the name <b>{{q}}</b> was found. ',
    offline: 'We cannot reach the server. Are you offline?',
    required: 'Please search for your bank',
    response: 'Unexpected error. Please contact support@bluecode.com.',
    invalid: 'Please check the marked field',
  },
  PageHeader: {
    title: 'Which bank account would you like to connect with?',
  },
  banks: {
    title: {
      searching: 'Search results',
      default: 'Search your bank or choose from the list',
    },
    'no-results': {
      title: 'No results',
      subtitle:
        'Unfortunately, there were no results for your search. Perhaps you made a typing error?',
      suggestion: 'Did you mean?',
    },
    'not-eligible': {
      title: 'The bank you are looking for is not eligible',
      subtitle:
        'This bank is not an official Bluecode partner. Please try a different bank',
    },
    partners: {
      title: 'Your bank does not participate in the raffle',
      description:
        'In just a few minutes you can open a new account online to take part in the Ticket Run',
      at: {
        title: 'bank99',
        description:
          'Open the right konto99 account throughout Austria and you have double the chances of winning.',
      },
      de: {
        title: 'Sparkasse<br />Saarbrücken',
        description:
          'Open your account anywhere in Germany in just a few minutes and take part in the Euro 2024 Ticket Run!',
      },
    },
  },
  Root: {
    'test-activation-scenarios': 'Test Activation Scenarios',
    'bank-accounts-without-payment-limits':
      'Bank accounts without payment limits',
    'bank-accounts-with-payment-limits': 'Bank accounts with payment limits',
    'bank-accounts-with-simulated-contract-state':
      'Bank accounts with simulated contract state',
    other: 'Other',
  },
  Success: {
    'activation-successful': 'Activation successful',
  },
  Limits: {
    'per-day': 'per day',
    'per-hour': 'per hour',
    'in-4-days': 'in 4 days',
    transactions: 'Transactions',
  },
};

export default en;
