const de = {
  BankSearchForm: {
    countries: {
      austria: '🇦🇹 AT',
      germany: '🇩🇪 DE',
      eu: '🇪🇺 EU',
    },
    back: 'Alle Banken',
    'banks-not-found':
      'Es konnte keine Bank zu deiner Suchanfrage gefunden werden.',
    confirm: 'Bestätigen',
    'not-found': {
      iban: '{{q}} ist deine IBAN. Wir wollen aber erstmal wissen, bei welcher Bank das Konto ist. Gebe den Namen der Bank an oder den BIC-Code deines Kontos.',
      long: ' Versuche nur einen Teil des Namens anzugeben.',
      main: 'Keine unterstützte Bank mit dem Namen {{q}} gefunden.',
      short: ' Wenn dies eine Abkürzung ist, versuche mit dem vollen Namen.',
    },
    'label-search': 'Name, BLZ oder BIC deiner Bank',
    'children-label-search': 'Suche in "{{bank}}"',
  },
  eu: {
    title: 'Europäische Union',
    description:
      'Starte im nächsten Schritt die Kontoverifizierung deines europäischen Bankkontos.',
    button: 'Kontoverifizierung starten',
  },
  ErrorPage: {
    'config-error':
      'Interner Fehler (fehlende Konfiguration). Bitte gehe zurück und versuche es erneut.',
    title: 'Unerwarteter Zustand',
  },
  errors: {
    'not-found': 'Keine unterstützte Bank mit dem Namen {{q}} gefunden.',
    offline: 'Server ist nicht erreichbar. Bist du offline?',
    required: 'Bitte suche nach deiner Bank',
    response: 'Unerwarteter Fehler. Bitte support@bluecode.com kontaktieren.',
    invalid: 'Bitt überprüfe die markierte Felder',
  },
  PageHeader: {
    title: 'Bei welcher Bank hast du dein Konto?',
  },
  banks: {
    title: {
      searching: 'Suchergebnisse',
      default: 'Suche deine Bank oder wähle aus der Liste',
    },
    'no-results': {
      title: 'Keine Ergebnisse',
      subtitle:
        'Hast du dich vielleicht vertippt oder das falsche Land in der Banksuche ausgewählt?',
      suggestion: 'Vorschläge:',
    },
    'not-eligible': {
      title: 'Die von dir gewählte Bank ist nicht zugelassen',
      subtitle:
        'Diese Bank ist kein offizieller Bluecode Partner. Bitte wähle eine andere Bank',
    },
    partners: {
      title: 'Deine Bank unterstützt leider die Aktion nicht',
      description:
        'In nur wenigen Minuten kannst du online ein neues Konto eröffnen um am Ticket-Run teilzunehmen',
      button: 'Jetzt Konto eröffnen',
      at: {
        title: 'bank99',
        description:
          'Eröffne österreichweit das passende konto99 und du hast gleich doppelte Gewinnchancen.',
      },
      de: {
        title: 'Sparkasse<br />Saarbrücken',
        description:
          'Eröffne deutschlandweit dein Konto in nur wenigen Minuten und nimm teil am EM 2024 Ticket-Run!',
      },
    },
  },
  Root: {
    'test-activation-scenarios': 'Test Aktivierungsszenarien',
    'bank-accounts-without-payment-limits': 'Bankkonten ohne Zahlungslimits',
    'bank-accounts-with-payment-limits': 'Bankkonten mit Zahlungslimits',
    'bank-accounts-with-simulated-contract-state':
      'Bankkonten mit simuliertem Vertragsstatus',
    other: 'Andere',
  },
  Success: {
    'activation-successful': 'Aktivierung erfolgreich',
  },
  Limits: {
    'per-day': 'pro Tag',
    'per-hour': 'pro Stunde',
    'in-4-days': 'in 4 Tagen',
    transactions: 'Transaktionen',
  },
};

export default de;
