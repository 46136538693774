import { FC, useCallback } from 'react';

import clsx from 'clsx';

import Loader from '../../Loader';
import Typo from '../../Typo';
import useTabs from '../useTabs';
import { ItemProps } from './Item.types';

export const Item: FC<ItemProps> = ({
  label,
  onClick,
  disabled = false,
  icon,
  iconPosition = 'left',
  testId,
}) => {
  const handleClick = useCallback(() => {
    !disabled && onClick?.();
  }, [disabled, onClick]);

  const { loading, isDense } = useTabs();

  return (
    <div
      onClick={handleClick}
      data-testid={testId}
      className={clsx(
        'relative flex h-full flex-1 flex-shrink-0 cursor-pointer items-center justify-center overflow-hidden  text-center text-gray-600 transition-colors duration-200',
        disabled && 'opacity-30',
      )}
    >
      {loading ? (
        <div className="relative w-full h-full">
          <Loader
            type="pulse"
            className="absolute top-0 left-0 right-0 bottom-0 rounded-[12px]"
            colorClassName="bg-gray-200 dark:bg-gray-500"
          />
        </div>
      ) : (
        <div className="flex items-center px-2 space-x-1 overflow-hidden text-gray-600 text-ellipsis whitespace-nowrap dark:text-gray-0">
          {iconPosition === 'left' && icon}
          <Typo size={isDense ? 'sm' : 'base'}>{label}</Typo>
          {iconPosition === 'right' && icon}
        </div>
      )}
    </div>
  );
};

export default Item;
