import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="chevron-left-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.4697 5.4697a.75.75 0 0 1 1.0606 1.0607l-1.0606-1.0607Zm1.0606 12a.75.75 0 0 1 0 1.0606.75.75 0 0 1-1.0606 0l1.0606-1.0606Zm0-10.9394-5.6464 5.6465-1.0607-1.0607 5.6465-5.6464 1.0606 1.0606Zm-5.6464 5.2929 5.6464 5.6465-1.0606 1.0606-5.6465-5.6464 1.0607-1.0607Zm0 .3536a.25.25 0 0 0 0-.3536l-1.0607 1.0607c-.4881-.4882-.4881-1.2796 0-1.7678l1.0607 1.0607Z"
    />
  </svg>
);
const Memo = memo(SvgChevronLeftIcon);
export default Memo;
