import { DeploymentTargetBci } from '@bluecodecom/types';

import { Config } from './types';

class ConfigService {
  private static instance: ConfigService;
  private _deploymentTarget!: DeploymentTargetBci;
  private _config!: Config;

  public get config(): Config {
    return this._config;
  }

  public get deploymentTarget() {
    return this._deploymentTarget;
  }

  public get domain() {
    // For local development with schema-services, the url will looks like this:
    // https://bank-search.your_name.bloomco.de
    // And we need that your_name.bloomco.de
    if (this._deploymentTarget === 'local') {
      const parts = window.location.hostname.split('bank-search.');

      return parts?.[1] || '';
    }

    switch (this._deploymentTarget) {
      case 'bci-dev':
        return 'bluecode.mobi';
      case 'bci-int':
        return 'bluecode.biz';
      case 'bci-prod':
        return 'bluecode.com';
      case 'ngc-int':
        return 'pay.int.bluecode.ng';
      case 'ngc-prod':
        return 'pay.bluecode.ng';
    }

    throw new Error('Unknown deployment target' + this._deploymentTarget);
  }

  public static getInstance() {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }

  public async init(
    deploymentTarget: DeploymentTargetBci,
    jwt?: string | null,
  ) {
    this._deploymentTarget = deploymentTarget;

    const r = await fetch(
      `https://app.${this.domain}/bank-search/api/v3/config`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );
    this._config = (await r.json()) as Config;

    return this._config;
  }
}

const configService = ConfigService.getInstance();

export default configService;
